// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faAngleDown      as farFaAngleDown      } from "@fortawesome/pro-regular-svg-icons/faAngleDown";
import { faClock          as farFaClock          } from "@fortawesome/pro-regular-svg-icons/faClock";
import { faComment        as farFaComment        } from "@fortawesome/pro-regular-svg-icons/faComment";
import { faFolder         as farFaFolder         } from "@fortawesome/pro-regular-svg-icons/faFolder";
import { faQuestionCircle as farFaQuestionCircle } from "@fortawesome/pro-regular-svg-icons/faQuestionCircle";
import { faSearch         as farFaSearch         } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { faTag            as farFaTag            } from "@fortawesome/pro-regular-svg-icons/faTag";
import { faUserCircle     as farFaUserCircle     } from "@fortawesome/pro-regular-svg-icons/faUserCircle";

import { faBars           as fasFaBars           } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faChevronLeft    as fasFaChevronLeft    } from "@fortawesome/pro-solid-svg-icons/faChevronLeft";
import { faChevronRight   as fasFaChevronRight   } from "@fortawesome/pro-solid-svg-icons/faChevronRight";

/**
 * Add regular icons
 */
library.add(farFaAngleDown, farFaClock, farFaComment, farFaFolder, farFaQuestionCircle, farFaSearch, farFaTag, farFaUserCircle);

/**
 * Add solid icons
 */
library.add(fasFaBars, fasFaChevronLeft, fasFaChevronRight);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
